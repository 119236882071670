import { makeAxiosRequest } from 'utils/apiMethods';
import queryString from 'query-string';

export default {
  getPreSignedUrls: (companyId, params) => makeAxiosRequest(
    `/admin/companies/${companyId}/seller-agreement/s3-pre-signed-urls?${
      queryString.stringify(params, { arrayFormat: 'bracket' })
    }`,
    { method: 'get' },
    true,
  ),
};
