import React from 'react';
import { Admin, Resource } from 'react-admin';
import { reducer as formReducer } from 'redux-form';

import { UserCreate, UserEdit, UserList } from './resources/users';

import HttpClient from './http/httpClient';
import { AuthProvider, UserRoles } from './providers/authProvider';
import { DataProvider } from './providers/dataProvider';
import CustomRoutes from './routes/customRoutes';

import LoginPage from './pages/login';
import CustomLayout from './layouts/customLayout';
import { SellerAgreementEdit } from './resources/sellerAgreement';
import { BuyerAgreementEdit } from './resources/buyerAgreement';
import { BankInformationEdit } from './resources/bankInformation';
import { ContactUsEdit, ContactUsList } from './resources/contactUs';
import { AdminList } from './resources/admins';
import { CompanyCreate, CompanyEdit, CompanyList } from './resources/companies';
import { PropertyEdit, PropertyList } from './resources/properties';
import { BidEdit, BidList } from './resources/bids';

import editListingReducer from './store/reducers/editListingReducer';
import companyAdminDocuments from './store/reducers/companyAdminDocuments';
import userAdminDocuments from './store/reducers/userAdminDocuments';
import editListingSagas from './store/sagas/editListingSagas';
import companyAdminDocumentSagas from './store/sagas/companyAdminDocumentSagas';
import userAdminDocumentSagas from './store/sagas/userAdminDocumentSagas';
import documentTemplatesReducer from './store/reducers/documentTemplatesReducer';
import documentTemplatesSagas from './store/sagas/documentTemplatesSagas';
import editSellerAgreementReducer from './store/reducers/editSellerAgreementReducer';
import editSellerAgreementSagas from './store/sagas/editSellerAgreementSagas';
import editBuyerAgreementReducer from './store/reducers/editBuyerAgreementReducer';
import editBuyerAgreementSagas from './store/sagas/editBuyerAgreementSagas';
import buyerAgreementTemplateReducer from './store/reducers/buyerAgreementTemplateReducer';
import buyerAgreementTemplateSagas from './store/sagas/buyerAgreementTemplateSagas';
import cloneListingReducer from './store/reducers/cloneListingReducer';
import cloneListingSagas from './store/sagas/cloneListingSagas';
import companiesReducer from './store/reducers/companiesReducer';
import companiesSagas from './store/sagas/companiesSagas';
import notificationsSagas from './store/sagas/notificationsSagas';
import statesReducer from 'store/reducers/statesReducer';
import listingAnalytics from 'store/reducers/listingAnalyticsReducer';
import statesSagas from 'store/sagas/statesSagas';
import listingAnalyticsSagas from 'store/sagas/listingAnalyticsSagas';
import {
  ComplianceTodoList,
  complianceTodosResourceName,
} from 'resources/complianceTodos';

const App = () => (
  <Admin
    dataProvider={DataProvider(process.env.API_URL, HttpClient)}
    authProvider={AuthProvider}
    loginPage={LoginPage}
    layout={CustomLayout}
    customReducers={{
      ...companyAdminDocuments,
      ...userAdminDocuments,
      ...editListingReducer,
      ...cloneListingReducer,
      ...companiesReducer,
      ...documentTemplatesReducer,
      ...editSellerAgreementReducer,
      ...editBuyerAgreementReducer,
      ...buyerAgreementTemplateReducer,
      ...statesReducer,
      ...listingAnalytics,
      form: formReducer,
    }}
    customRoutes={CustomRoutes}
    customSagas={[
      companyAdminDocumentSagas,
      userAdminDocumentSagas,
      editListingSagas,
      cloneListingSagas,
      companiesSagas,
      documentTemplatesSagas,
      editSellerAgreementSagas,
      editBuyerAgreementSagas,
      buyerAgreementTemplateSagas,
      statesSagas,
      listingAnalyticsSagas,
      notificationsSagas,
    ]}
  >
    {permissions => [
      <Resource
        key="users"
        name="users"
        create={UserCreate}
        edit={UserEdit}
        list={UserList}
      />,
      permissions?.includes(UserRoles.SuperAdmin)
        ? (
          <Resource
            key="admins"
            name="admins"
            list={AdminList}
            create={UserCreate}
            edit={UserEdit}
          />
        ) : null,
      <Resource
        key="companies"
        name="companies"
        list={CompanyList}
        create={CompanyCreate}
        edit={CompanyEdit}
      />,
      <Resource
        key="properties"
        name="properties"
        list={PropertyList}
        edit={PropertyEdit}
      />,
      <Resource
        key="bids"
        name="bids"
        list={BidList}
        edit={BidEdit}
      />,
      permissions?.includes(UserRoles.CompliancePerson) || permissions?.includes(UserRoles.SuperAdmin)
        ? (
          <Resource
            key={complianceTodosResourceName}
            name={complianceTodosResourceName}
            list={ComplianceTodoList}
          />
        ) : null,
      permissions?.includes(UserRoles.Admin)
        ? (
          <Resource
            key="contact-us"
            name="contact-us"
            list={ContactUsList}
            edit={ContactUsEdit}
          />
        ) : null,
      <Resource
        key="seller-agreements"
        name="seller-agreements"
        edit={SellerAgreementEdit}
      />,
      <Resource
        key="buyer-agreements"
        name="buyer-agreements"
        edit={BuyerAgreementEdit}
      />,
      <Resource
        key="bank-information"
        name="bank-information"
        edit={BankInformationEdit}
      />,
    ]}
  </Admin>
);
export default App;
