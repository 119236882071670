import React, { useRef, useState } from 'react';
import {
  Button,
  Edit,
  FormDataConsumer,
  maxLength,
  minLength,
  number,
  email,
  required,
  SimpleForm,
  ChipField,
  TextInput,
  TopToolbar,
  useNotify,
  useRefresh,
  Labeled,
  FunctionField,
  linkToRecord,
} from 'react-admin';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import { generateFetchOptions } from '../helpers/fetch';
import {
  ApprovalStatus,
  isRejectionReasonRequired,
} from 'now-shared/validation/approval-status';
import {
  getStandardCreateEditProps,
  getStandardFormProps,
} from 'components/standard-form-props';
import {
  parseServerError, ServerError,
} from 'now-shared/helpers/server-errors';
import FormGetter from 'components/FormGetter';
import { createAdminResourceRejectionReasonValidator } from 'validations/validateRejectionReason';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import LinkButton from 'now-frontend-shared/components/LinkButton';

const canAccept = record => record.status.title !== ApprovalStatus.Approved && !!record.requestedBidAllowance;
const canReject = record => record.status.title !== ApprovalStatus.Rejected;

const BankInformationEditActions = props => {
  const {
    form,
  } = props;
  const notify = useNotify();
  const refresh = useRefresh();

  const onStatusChange = ({ status, rejectionReason = null }) => {
    const options = generateFetchOptions('PUT', { status, rejectionReason });

    fetch(`${process.env.REACT_APP_API_URL}/bank-information/${props.data.id}/change-status`, options).then(async response => {
      if (response.status === 200) {
        notify('Status changed');
        refresh();
      } else {
        let message;
        try {
          message = parseServerError(await response.json()).friendlyMessage;
        } catch (error) {
          message = ServerError.Generic;
        }
        notify(message, 'warning');
      }
    });
  };

  return (
    <TopToolbar>
      {props.data && canAccept(props.data) && (
        <Button
          label="APPROVE"
          onClick={() => onStatusChange({ status: ApprovalStatus.Approved })}
        >
          <CheckIcon />
        </Button>
      )}
      {props.data && canReject(props.data) && (
        <Button
          label="REJECT"
          onClick={() => onStatusChange({
            status: ApprovalStatus.Rejected,
            rejectionReason: form.getFieldState('rejectionReason').value,
          })}
          disabled={!form}
        >
          <HighlightOffIcon />
        </Button>
      )}
    </TopToolbar>
  );
};

export const BankInformationEdit = props => {
  const notify = useNotify();
  const [finalForm, setFinalForm] = useState(undefined);

  const { current: validateRejectionReason }
    = useRef(createAdminResourceRejectionReasonValidator('status', canReject));

  return (
    <Edit
      {...getStandardCreateEditProps({ notify, ...props })}
      actions={<BankInformationEditActions form={finalForm} />}
    >
      <SimpleForm
        {...getStandardFormProps({ ...props })}
      >
        <FormGetter onForm={setFinalForm} />
        <ChipField source="status.title" label="Status" />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            canReject(formData)
            || formData.status.title === ApprovalStatus.Rejected
          ) && (
            <TextInput
              {...rest}
              source="rejectionReason"
              label="Reason for Rejection"
              isRequired={isRejectionReasonRequired(formData, 'status')}
              disabled={
                // TODO: [INTEGRITY][REQUIREMENTS] don't allow changing rejection reason once it
                // has been saved?
                formData.status.title !== ApprovalStatus.Rejected
                && !canReject(formData)
              }
              fullWidth
              multiline
              minRows={1}
              maxRows={8}
              validate={validateRejectionReason}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.approvedAt && (
            <Labeled label={`${formData.status.title === 'approved' ? 'Approved' : 'Rejected'} At`}>
              <DateFieldWithTimeZone
                {...rest}
                source="approvedAt"
                showTime
                timeZone={NONOPWELLS_TIME_ZONE}
              />
            </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.approvedBy && (
            <Labeled label={`${formData.status.title === 'approved' ? 'Approved' : 'Rejected'} By`}>
              <FunctionField
                render={record => record.approvedBy && (
                <LinkButton
                  label={getUserFullName(record.approvedBy)}
                  buttonColor="clearGreen"
                  path={linkToRecord('/users', record.approvedBy.id)}
                />
                )}
                {...rest}
              />
            </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.companyId && (
          <Labeled
            label="Company"
          >
            <FunctionField
              render={record => record.companyId && (
                <LinkButton
                  label={getCompanyName(record.company) || record.companyId}
                  buttonColor="clearGreen"
                  path={linkToRecord('/companies', record.companyId)}
                />
              )}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <TextInput source="bankName" label="Bank Name" validate={[required(), minLength(2), maxLength(200)]} />
        <TextInput source="bankerName" label="Name of Banker" validate={[required(), minLength(2), maxLength(200)]} />
        <TextInput source="bankerEmail" label="Email of Banker" type="email" validate={[required(), email(), minLength(2), maxLength(100)]} />
        <TextInput source="bankerPhoneNumber" label="Telephone of Banker" validate={[required(), minLength(2), maxLength(20)]} />
        <TextInput source="bankerAddress" label="Address of Bank" validate={[minLength(2), maxLength(200)]} />
        <TextInput
          source="notes"
          label="Notes/Instructions"
          multiline
          minRows={1}
          maxRows={8}
          validate={[minLength(2), maxLength(2000)]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.comment && (
            <TextInput
              {...rest}
              source="comment"
              label="Comment (Deprecated)"
              disabled
              fullWidth
              multiline
              minRows={1}
              validate={[minLength(2), maxLength(200)]}
            />
          )}
        </FormDataConsumer>
        <TextInput
          source="requestedBidAllowance"
          label="Requested Bid Allowance"
          validate={[required(), minLength(2), maxLength(200), number()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.updatedBy && (
          <Labeled
            label="Updated By"
          >
            <FunctionField
              render={record => record.updatedBy && (
                <LinkButton
                  label={getUserFullName(record.updatedBy)}
                  buttonColor="clearGreen"
                  path={linkToRecord('/users', record.updatedBy.id)}
                />
              )}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.updatedAt && (
          <Labeled
            label="Updated At"
          >
            <DateFieldWithTimeZone
              source="updatedAt"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.createdBy && (
          <Labeled
            label="Created By"
          >
            <FunctionField
              render={record => record.createdBy && (
                <LinkButton
                  label={getUserFullName(record.createdBy)}
                  buttonColor="clearGreen"
                  path={linkToRecord('/users', record.createdBy.id)}
                />
              )}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.createdAt && (
          <Labeled
            label="Created At"
          >
            <DateFieldWithTimeZone
              source="createdAt"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
