import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  DeleteButton, SaveButton, Toolbar, Button,
} from 'react-admin';
import DeleteButtonWithConfirmation from './deleteButtonWithConfirmation';
import { bidStatusIsHistorical } from 'now-shared/validation/bid-validation';
import { canDeleteListing, listingHasBeenClosedOrArchived } from 'now-shared/validation/listing-validation';
import {
  shouldAllowAdminToEditWellNetAfeOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellGrossAfeOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellNameOnListingThatHasBeenClosedOrArchived,
} from 'now-shared/config/admin-settings';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export const ResourceToolbar = compose(
  connect(({
    editListing: {
      unloadedFilesExist: unloadedListingFilesExist,
    },
    editSellerAgreement: {
      unloadedFilesExist: unloadedSellerAgreementFilesExist,
      AWSData: sellerAgreementFiles,
    },
    editBuyerAgreement: {
      unloadedFilesExist: unloadedBuyerAgreementFilesExist,
      AWSData: buyerAgreementFiles,
    },
    companyAdminDocuments: {
      unloadedFilesExist: unloadedCompanyComplianceFilesExist,
      AWSData: companyComplianceFiles,
    },
    userAdminDocuments: {
      unloadedFilesExist: unloadedUserComplianceFilesExist,
      AWSData: userComplianceFiles,
    },
  }) => ({
    unloadedListingFilesExist,
    unloadedSellerAgreementFilesExist,
    sellerAgreementFiles,
    unloadedBuyerAgreementFilesExist,
    buyerAgreementFiles,
    unloadedCompanyComplianceFilesExist,
    companyComplianceFiles,
    unloadedUserComplianceFilesExist,
    userComplianceFiles,
  })),
  withStyles(styles),
)(({
  resource,
  classes,
  disableDelete,
  disableSave,
  deletingConfirmation,
  unloadedListingFilesExist,
  unloadedSellerAgreementFilesExist,
  sellerAgreementFiles,
  unloadedBuyerAgreementFilesExist,
  buyerAgreementFiles,
  unloadedCompanyComplianceFilesExist,
  companyComplianceFiles,
  unloadedUserComplianceFilesExist,
  userComplianceFiles,
  ...props
}) => {
  const toBack = ({ history }) => () => history.length > 1 && history.goBack();
  let showSaveButton = !disableSave;
  let showDeleteButton = !disableDelete;
  let label;

  const saveWithoutUploadingMessage = 'Save Without Uploading Document(s)';
  const saveOnlyOneDocumentMessage = 'Save (Only One Document Will Be Saved)';

  // TODO: [SIMPLIFY][CONVENTIONS] convert this if else if control structure into a switch structure
  if (resource === 'properties') {
    if (
      listingHasBeenClosedOrArchived(props.record)
      && !shouldAllowAdminToEditWellNetAfeOnListingThatHasBeenClosedOrArchived
      && !shouldAllowAdminToEditWellGrossAfeOnListingThatHasBeenClosedOrArchived
      && !shouldAllowAdminToEditWellNameOnListingThatHasBeenClosedOrArchived
    ) {
      showSaveButton = false;
    } else if (unloadedListingFilesExist) {
      label = saveWithoutUploadingMessage;
    }
    if (!canDeleteListing(props.record)) {
      showDeleteButton = false;
    }
  } else if (resource === 'bids') {
    if (bidStatusIsHistorical(props.record)) {
      showSaveButton = false;
      showDeleteButton = false;
    }
  } else if (resource === 'seller-agreements') {
    if (unloadedSellerAgreementFilesExist) {
      label = saveWithoutUploadingMessage;
    } else if (sellerAgreementFiles.length > 1) {
      label = saveOnlyOneDocumentMessage;
    }
  } else if (resource === 'buyer-agreements') {
    if (unloadedBuyerAgreementFilesExist) {
      label = saveWithoutUploadingMessage;
    } else if (buyerAgreementFiles.length > 1) {
      label = saveOnlyOneDocumentMessage;
    }
  } else if (resource === 'companies') {
    if (unloadedCompanyComplianceFilesExist || companyComplianceFiles.find(doc => !doc.id)) {
      label = saveWithoutUploadingMessage;
    }
  } else if (['users', 'admins'].includes(resource)) {
    if (unloadedUserComplianceFilesExist || userComplianceFiles.find(doc => !doc.id)) {
      label = saveWithoutUploadingMessage;
    }
  }

  // TODO: [BUG] redirect={toBack(props)} is not working for the case when you load an admin edit page without first
  // going to the list view. In this case, it doesn't go back to the list view as intended, because the list view
  // page is not in the history.

  return props.location.pathname.endsWith(props.id)
    ? (
      <Toolbar classes={ classes } resource={resource} { ...props }>
        { showSaveButton && (
        <SaveButton
          label={label}
          redirect={toBack(props)}
        />
        )}
        <Button label="BACK" onClick={toBack(props)} />
        { showDeleteButton && (deletingConfirmation
          ? <DeleteButtonWithConfirmation />
          : <DeleteButton redirect={toBack(props)} />)}
      </Toolbar>
    )
    : null;
});
