import validator from 'validator';

export const isLatLong = type => value => {
  if (value) {
    if (type === 'latitude' && !validator.isLatLong(`${value},0`)) {
      return 'Must be correct latitude';
    }

    if (type === 'longitude' && !validator.isLatLong(`0,${value}`)) {
      return 'Must be correct longitude';
    }
  }
};
