/* eslint-disable react/prop-types */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import {
  BooleanInput,
  Create,
  Filter,
  TextInput,
  List,
  Datagrid,
  FunctionField,
  TextField,
  BooleanField,
  Edit,
  SimpleForm,
  TopToolbar,
  EmailField,
  NumberField,
  AutocompleteInput,
  Button,
  useNotify,
  useRefresh,
  usePermissions,
  Labeled,
  linkToRecord,
  ChipField,
  NullableBooleanInput,
  SelectInput,
} from 'react-admin';
import { DataGrid } from '@material-ui/data-grid';
import IconSaveAlt from '@material-ui/icons/SaveAlt';
import {
  getPreSignedUrls,
  removeAWSDataFile,
  saveUserComplianceDocuments,
  getUserComplianceDocuments,
  setAllAWSData,
  setAWSData,
  setUnloadedFilesExist,
  deleteUserComplianceDocument,
} from 'store/actions/user-admin-documents';
import Typography from '@material-ui/core/Typography';
import DeleteButtonWithConfirmation from '../components/deleteButtonWithConfirmation';
import DeleteButtonWithDialog from 'components/deleteButtonWithDialog';
import { CustomPagination } from '../components/pagination';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {
  getAllCompanies,
  getUserCompany,
  clearUserCompany,
} from 'store/actions/companies-actions';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE, nonOpWellsDateTime } from 'now-shared/helpers/time-helpers';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { generateFetchOptions } from '../helpers/fetch';
import { isUserRegistrationComplete } from 'now-shared/helpers/auth-helpers';
import { UserRoles } from 'providers/authProvider';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  adminUpsertUserFieldValidators,
  adminOrCompliancePersonAssociatedWithCompanyError,
  canBeAdmin,
  canBeCompliancePerson,
  canBeNonAdmin,
  canBeNonCompliancePerson,
  canBeSuperAdmin,
  canBeNonSuperAdmin,
  canRejectUserRegistration,
  hasPermissionToEditUserField,
  isAdminOrCompliancePerson,
  canBeAuthorizedSigner,
  canBeNonAuthorizedSigner,
  canBeAccountManager,
  canBeNonAccountManager,
  canBeVerifiedEmail,
  canBeNonVerifiedEmail,
  hasCompanyOrCompanyRole,
  fieldsNotForCreate,
  adminAndComplianceRoleFields,
  companyAndCompanyRoleFields,
  defaultValuesForCreateAdmin,
  defaultValuesForCreateUser,
  isFieldRequired,
  canBeViewOnlyListingEditor,
  canBeNonViewOnlyListingEditor,
} from 'now-shared/validation/admin-upsert-user';
import { shouldIncludeAdminsInUsersList } from 'now-shared/config/admin-settings';
import { getUserData } from 'auth/auth-helpers';
import FormDataConsumer from 'components/FormDataConsumer';
import {
  disabledWrapper,
  isUiValidationDisabled,
  makeFormValidWrapper,
} from 'now-frontend-shared/helpers/ui-validation-toggle';
import FormSpyForDataProvider from 'components/FormSpyForDataProvider';
import {
  getStandardCreateEditProps,
  getStandardFormProps,
} from 'components/standard-form-props';
import { setFormFieldValue } from 'helpers/form-helpers';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import {
  parseServerError, ServerError,
} from 'now-shared/helpers/server-errors';
import {
  getAdminResourceRejectionReasonValidators,
} from 'validations/validateRejectionReason';
import { createFormValidator } from 'now-shared/validation/validation-rules';
import {
  ApprovalStatus,
  isRejectionReasonRequired,
  getAllApprovalStatuses,
  noApprovalStatusId,
} from 'now-shared/validation/approval-status';
import FormGetter from 'components/FormGetter';
import useRateLimitedFormValidator from 'hooks/useRateLimitedFormValidator';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import { SortDirection } from 'now-shared/enums/sort-direction';
import DropZone from 'now-frontend-shared/components/DropZone';
import { viewOnlyListingEditorRoleTitle } from 'now-shared/config/role-constants';

export const UsersFilter = props => (
  <Filter {...props}>
    <TextInput label="Name / Email / Company" source="search" alwaysOn />
    <NullableBooleanInput label="Active" source="active" alwaysOn />
    <SelectInput
      label="Approval"
      allowEmpty
      emptyValue=""
      source="approvalStatus"
      alwaysOn
      choices={[
        {
          id: noApprovalStatusId,
          name: '<none>',
        },
        ...getAllApprovalStatuses().map(status => ({ id: status, name: status })),
      ]}
    />
  </Filter>
);

const UserEditActions = ({
  data,
  form,
  userCompany,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { loaded: permissionsLoaded, permissions } = usePermissions();
  const dispatch = useDispatch();

  const userHasCompletedRegistration = isUserRegistrationComplete({
    ...data,
    company: userCompany,
  });

  const companyId = data?.companyId;

  const userIsComplianceSupervisor = data?.isComplianceSupervisor;
  const userIsRegisteredRepresentative = data?.isRegisteredRepresentative;
  const userIsAdmin = data?.isAdmin;

  useEffect(() => {
    if (
      companyId
      && isAdminOrCompliancePerson({
        isComplianceSupervisor: userIsComplianceSupervisor,
        isRegisteredRepresentative: userIsRegisteredRepresentative,
        isAdmin: userIsAdmin,
      })
    ) {
      notify(
        adminOrCompliancePersonAssociatedWithCompanyError.message,
        {
          type: 'warning',
          autoHideDuration: 10000,
        },
      );
    }
  }, [
    notify,
    companyId,
    userIsComplianceSupervisor,
    userIsRegisteredRepresentative,
    userIsAdmin,
  ]);

  useEffect(() => {
    if (companyId) {
      dispatch(getUserCompany({ id: companyId }));
    } else {
      dispatch(clearUserCompany());
    }
  }, [dispatch, companyId]);

  const onStatusChange = async ({ approvalStatus, rejectionReason = null }) => {
    const options = generateFetchOptions('PUT', { approvalStatus, rejectionReason });

    const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${data.id}/change-approval-status`, options);
    if (response.status === 200) {
      notify('Approval status change saved');
      refresh();
    } else {
      let message;
      try {
        message = parseServerError(await response.json()).friendlyMessage;
      } catch (error) {
        message = ServerError.Generic;
      }
      notify(message, 'warning');
    }
  };

  const isAdmin = permissionsLoaded && permissions.includes(UserRoles.Admin);

  return (
    <TopToolbar>
      {(
        data
        && !data.isAdmin
        && data.companyId
      ) && (
        <>
          {(
            [
              ApprovalStatus.Pending,
              ApprovalStatus.Rejected,
            ].includes(data.approvalStatus?.title)
          ) && (
            <Button
              label={!userHasCompletedRegistration ? 'User registration incomplete' : 'APPROVE REGISTRATION'}
              onClick={() => onStatusChange({ approvalStatus: ApprovalStatus.Approved })}
              disabled={disabledWrapper(() => (
                !userHasCompletedRegistration
                || !isAdmin
              ))}
            >
              {!userHasCompletedRegistration ? <HighlightOffIcon /> : <CheckIcon />}
            </Button>
          )}
          {canRejectUserRegistration(data) && (
            <Button
              label="REJECT REGISTRATION"
              onClick={() => onStatusChange({
                approvalStatus: ApprovalStatus.Rejected,
                rejectionReason: form.getFieldState('rejectionReason').value,
              })}
              disabled={!form || disabledWrapper(() => !isAdmin)}
            >
              <HighlightOffIcon />
            </Button>
          )}
        </>
      )}
    </TopToolbar>
  );
};

export const UserList = props => (
  <List
    {...props}
    filters={<UsersFilter />}
    sort={{
      field: 'id',
      order: SortDirection.Descending,
    }}
    pagination={<CustomPagination />}
    exporter={false}
    perPage={50}
    bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" label="ID" />
      {shouldIncludeAdminsInUsersList && (
        <BooleanField source="isAdmin" label="Admin" />
      )}
      {shouldIncludeAdminsInUsersList && (
        <BooleanField source="isSuperAdmin" label="Super Admin" />
      )}
      {shouldIncludeAdminsInUsersList && (
        <BooleanField source="isComplianceSupervisor" label="Compliance Supervisor" />
      )}
      {shouldIncludeAdminsInUsersList && (
        <BooleanField source="isRegisteredRepresentative" label="Registered Representative" />
      )}
      <DateFieldWithTimeZone
        source="createdAt"
        label="Created"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="lastSignIn"
        label="Last Sign In"
        showTime
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <BooleanField source="active" label="Active" />
      <BooleanField source="isVerifiedEmail" label="Email Verified" />
      <ChipField source="approvalStatus.title" label="Registration Approval" sortBy="approvalStatus" />
      <FunctionField render={record => getUserFullName(record)} label="Name" sortBy="firstName" />
      <EmailField source="email" label="Email" />
      <TextField source="company.fullLegalCompanyName" label="Company" sortBy="company" />
      <BooleanField source="isViewOnlyListingEditor" label={viewOnlyListingEditorRoleTitle} />
      <BooleanField source="isAuthorizedSigner" label="Authorized Signer" />
      <BooleanField source="isAccountManager" label="Account Manager" />
      <TextField source="department" label="Department" />
      <TextField source="jobTitle" label="Job Title" />
      <TextField source="phoneNumber" label="Phone" />
    </Datagrid>
  </List>
);

const UserForm = ({
  companies,
  formType,
  resource,
  isLoadingCompanies,
  form: finalForm,
  onForm,
  ...props
}) => {
  const notify = useNotify();

  const userData = useMemo(
    () => getUserData(),
    [],
  );

  const isForCreate = formType === 'create';

  const disassociateFromCompanyRole = (form, formData) => {
    if (hasCompanyOrCompanyRole(formData)) {
      notify('Disassociating user from company and company role(s)');
      setFormFieldValue(form, 'companyId', null);
      setFormFieldValue(form, 'isAccountManager', false);
      setFormFieldValue(form, 'isAuthorizedSigner', false);
      setFormFieldValue(form, 'isViewOnlyListingEditor', false);
    }
  };

  const removeViewOnlyListingEditorRole = (form, formData) => {
    if (formData.isViewOnlyListingEditor) {
      notify(`Removing ${viewOnlyListingEditorRoleTitle} role`);
      setFormFieldValue(form, 'isViewOnlyListingEditor', false);
    }
  };

  const { isSuperAdmin } = userData;

  const hasPermissionToEdit = (user, field) => hasPermissionToEditUserField(
    userData,
    user,
    field,
  );

  const shouldShowField = useCallback((values, field, form) => {
    const fieldState = form?.getFieldState(field);
    let result;
    if (isUiValidationDisabled()) {
      result = true;
    } else if (values[field]) {
      result = true;
    } else if (fieldState?.dirty) {
      result = true;
    } else if (isForCreate && fieldsNotForCreate.includes(field)) {
      result = false;
    } else if (companyAndCompanyRoleFields.includes(field)) {
      result = (
        resource === 'users'
        && !isAdminOrCompliancePerson(values)
      );
    } else if (adminAndComplianceRoleFields.includes(field)) {
      result = (
        resource === 'admins'
        || (
          resource === 'users'
          && (
            shouldIncludeAdminsInUsersList
            || isSuperAdmin
          )
          && !hasCompanyOrCompanyRole(values)
        )
      );
    } else {
      result = true;
    }
    return result;
  }, [isForCreate, isSuperAdmin, resource]);

  const shouldDisableField = (formData, field, checkCustom) => disabledWrapper(() => {
    let result = false;
    if (!hasPermissionToEdit(formData, field)) {
      result = true;
    } else if (checkCustom) {
      result = checkCustom(field, formData);
    }
    return result;
  });

  const { current: validateUserUpsert } = useRef(
    createFormValidator({
      ...adminUpsertUserFieldValidators,
      rejectionReason: getAdminResourceRejectionReasonValidators(
        'approvalStatus',
        canRejectUserRegistration,
      ),
    }),
  );

  // NOTE: disabled for now, as it seems performance is actually better with it turned off.
  const doRateLimitFormValidation = false;

  const rateLimitedValidate = useRateLimitedFormValidator({
    validate: validateUserUpsert,
    form: finalForm,
  });

  const validateForm = doRateLimitFormValidation ? rateLimitedValidate : validateUserUpsert;

  return (
    <SimpleForm
      {...getStandardFormProps({ isForCreate, ...props })}
      redirect={`/${resource}`}
      {...isForCreate && {
        initialValues: resource === 'admins'
          ? defaultValuesForCreateAdmin
          : defaultValuesForCreateUser,
      }}
      validate={validateForm}
    >
      <FormGetter onForm={onForm} />
      <FormSpyForDataProvider />
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'active', form) && (
          <BooleanInput
            {...rest}
            source="active"
            label="Active"
            isRequired={isFieldRequired(formData, 'active')}
            disabled={shouldDisableField(formData, 'active')}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'isVerifiedEmail', form) && (
          <BooleanInput
            {...rest}
            source="isVerifiedEmail"
            label="Email Verified"
            options={{
              checkedIcon: <VerifiedUserIcon />,
            }}
            isRequired={isFieldRequired(formData, 'isVerifiedEmail')}
            disabled={shouldDisableField(formData, 'isVerifiedEmail', () => (
              !formData.isVerifiedEmail
                ? !canBeVerifiedEmail(formData)
                : !canBeNonVerifiedEmail({
                  ...formData,
                  // allow un-setting isVerifiedEmail when Super Admin is set, to show the
                  // validation error message.
                  isSuperAdmin: false,
                })
            ))}
          />
        )}
      </FormDataConsumer>
      {!isForCreate && (
        <FormDataConsumer>
          {({ formData, form, ...rest }) => formData.approvalStatus && (
            <Labeled
              label="Registration Approval"
            >
              <ChipField
                {...rest}
                source="approvalStatus.title"
                label="Registration Approval"
              />
            </Labeled>
          )}
        </FormDataConsumer>
      )}
      {!isForCreate && (
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            canRejectUserRegistration(formData)
            || formData.approvalStatus?.title === ApprovalStatus.Rejected
          ) && (
            <TextInput
              {...rest}
              source="rejectionReason"
              label="Reason for Rejection (INTERNAL)"
              isRequired={isRejectionReasonRequired(formData, 'approvalStatus')}
              disabled={
                // TODO: [INTEGRITY][REQUIREMENTS] don't allow changing rejection reason once it
                // has been saved?
                formData.approvalStatus?.title !== ApprovalStatus.Rejected
                && !canRejectUserRegistration(formData)
              }
              fullWidth
              multiline
              minRows={1}
              maxRows={8}
            />
          )}
        </FormDataConsumer>
      )}
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'email', form) && (
          <TextInput
            {...rest}
            source="email"
            label="Email"
            {...isForCreate && {
              autoFocus: true,
            }}
            onChange={makeFormValidWrapper(() => {
              if (form.isVerifiedEmail) {
                notify('Marking user email not verified');
                setFormFieldValue(form, 'isVerifiedEmail', false);
              }
            })}
            isRequired={isFieldRequired(formData, 'email')}
            disabled={shouldDisableField(formData, 'email')}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'firstName', form) && (
          <TextInput
            {...rest}
            source="firstName"
            label="First Name"
            isRequired={isFieldRequired(formData, 'firstName')}
            disabled={shouldDisableField(formData, 'firstName')}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'lastName', form) && (
          <TextInput
            {...rest}
            source="lastName"
            label="Last Name"
            isRequired={isFieldRequired(formData, 'lastName')}
            disabled={shouldDisableField(formData, 'lastName')}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'companyId', form) && (
          <AutocompleteInput
            {...rest}
            source="companyId"
            label="Company"
            optionText="fullLegalCompanyName"
            optionValue="id"
            choices={companies}
            allowEmpty
            emptyValue={null}
            emptyText="<none>"
            shouldRenderSuggestions={value => value.trim().length > 0}
            suggestionLimit={10}
            isRequired={isFieldRequired(formData, 'companyId')}
            disabled={isLoadingCompanies || shouldDisableField(formData, 'companyId', () => (
              // TODO: [FEATURE][CLEANUP] remove the following condition once a migration is run to
              // disassociate all existing Admins from companies. For now, allow them to edit
              // the company only if they are already associated with a company, and only
              // allow them to select <none>.
              !formData.companyId
              && isAdminOrCompliancePerson(formData)
            ))}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => !!formData.companyId && (
          <LinkButton
            label={
              getCompanyName(
                formData.company
                || companies.find(company => company.id === formData.companyId),
              )
            }
            buttonColor="clearGreen"
            path={linkToRecord('/companies', formData.companyId)}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'department', form) && (
          <TextInput
            {...rest}
            source="department"
            label="Department"
            isRequired={isFieldRequired(formData, 'department')}
            disabled={shouldDisableField(formData, 'department')}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'jobTitle', form) && (
          <TextInput
            {...rest}
            source="jobTitle"
            label="Job Title"
            isRequired={isFieldRequired(formData, 'jobTitle')}
            disabled={shouldDisableField(formData, 'jobTitle')}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'phoneNumber', form) && (
          <TextInput
            {...rest}
            source="phoneNumber"
            label="Phone Number"
            isRequired={isFieldRequired(formData, 'phoneNumber')}
            disabled={shouldDisableField(formData, 'phoneNumber')}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'isViewOnlyListingEditor', form) && (
          <BooleanInput
            {...rest}
            source="isViewOnlyListingEditor"
            label={viewOnlyListingEditorRoleTitle}
            onChange={makeFormValidWrapper(value => {
              if (value && formData.isAuthorizedSigner) {
                notify('Removing Authorized Signer role');
                setFormFieldValue(form, 'isAuthorizedSigner', false);
                setFormFieldValue(form, 'isAccountManager', false);
              }
            })}
            isRequired={isFieldRequired(formData, 'isViewOnlyListingEditor')}
            disabled={shouldDisableField(formData, 'isViewOnlyListingEditor', () => (
              !formData.isViewOnlyListingEditor
                ? !canBeViewOnlyListingEditor({
                  ...formData,
                  // allow switching to View-Only + Listings when Authorized Signer is set
                  isAuthorizedSigner: false,
                })
                : !canBeNonViewOnlyListingEditor(formData)
            ))}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'isAuthorizedSigner', form) && (
          <BooleanInput
            {...rest}
            source="isAuthorizedSigner"
            label="Authorized Signer"
            onChange={makeFormValidWrapper(value => {
              if (!value && formData.isAccountManager) {
                notify('Removing Account Manager role');
                setFormFieldValue(form, 'isAccountManager', false);
              } else if (value) {
                removeViewOnlyListingEditorRole(form, formData);
              }
            })}
            isRequired={isFieldRequired(formData, 'isAuthorizedSigner')}
            disabled={shouldDisableField(formData, 'isAuthorizedSigner', () => (
              !formData.isAuthorizedSigner
                ? !canBeAuthorizedSigner({
                  ...formData,
                  // allow switching to Authorized Signer when View-Only + Listings is set
                  isViewOnlyListingEditor: false,
                })
                : !canBeNonAuthorizedSigner({
                  ...formData,
                  // allow switching to non Authorized Signer when Account Manager is set
                  isAccountManager: false,
                })
            ))}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'isAccountManager', form) && (
          <BooleanInput
            {...rest}
            source="isAccountManager"
            label="Account Manager"
            onChange={makeFormValidWrapper(value => {
              if (value) {
                setFormFieldValue(form, 'isAuthorizedSigner', true);
                removeViewOnlyListingEditorRole(form, formData);
              }
            })}
            isRequired={isFieldRequired(formData, 'isAccountManager')}
            disabled={shouldDisableField(formData, 'isAccountManager', () => (
              !formData.isAccountManager
                ? !canBeAccountManager({
                  ...formData,
                  // allow switching to Account Manager when Authorized Signer is not set
                  isAuthorizedSigner: true,
                  // allow switching to Authorized Signer when View Only + Listing is set
                  isViewOnlyListingEditor: false,
                })
                : !canBeNonAccountManager(formData)
            ))}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'isAdmin', form) && (
          <BooleanInput
            {...rest}
            source="isAdmin"
            label="Admin"
            onChange={makeFormValidWrapper(value => {
              if (value) {
                disassociateFromCompanyRole(form, formData);
              } else if (formData.isSuperAdmin) {
                notify('Removing Super Admin role');
                setFormFieldValue(form, 'isSuperAdmin', false);
              }
            })}
            isRequired={isFieldRequired(formData, 'isAdmin')}
            disabled={shouldDisableField(formData, 'isAdmin', () => (
              !formData.isAdmin
                ? !canBeAdmin(formData)
                : !canBeNonAdmin({
                  ...formData,
                  // allow unsetting Admin when Super Admin is set
                  isSuperAdmin: false,
                })
            ))}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'isSuperAdmin', form) && (
          <BooleanInput
            {...rest}
            source="isSuperAdmin"
            label="Super Admin"
            onChange={makeFormValidWrapper(value => {
              if (value) {
                disassociateFromCompanyRole(form, formData);
                setFormFieldValue(form, 'isAdmin', true);
              }
            })}
            isRequired={isFieldRequired(formData, 'isSuperAdmin')}
            disabled={shouldDisableField(formData, 'isSuperAdmin', () => (
              !formData.isSuperAdmin
                ? !canBeSuperAdmin({
                  ...formData,
                  // allow switching between Admin and Super Admin without having to first
                  // toggle the other one off
                  isAdmin: true,
                  // allow setting Super Admin when email isn't verified, to show the
                  // validation error message.
                  isVerifiedEmail: true,
                })
                : !canBeNonSuperAdmin(formData)
            ))}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'isRegisteredRepresentative', form) && (
          <BooleanInput
            {...rest}
            source="isRegisteredRepresentative"
            label="Registered Representative"
            onChange={makeFormValidWrapper(value => {
              if (value) {
                if (formData.isComplianceSupervisor) {
                  notify('Removing Compliance Supervisor role');
                  setFormFieldValue(form, 'isComplianceSupervisor', false);
                }
              }
            })}
            isRequired={isFieldRequired(formData, 'isRegisteredRepresentative')}
            disabled={shouldDisableField(formData, 'isRegisteredRepresentative', () => (
              !formData.isRegisteredRepresentative
                ? !canBeCompliancePerson({
                  ...formData,
                  // allow switching between compliance person types without having to first
                  // toggle the other one off
                  isComplianceSupervisor: false,
                }) : !canBeNonCompliancePerson(formData)
            ))}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, form, ...rest }) => shouldShowField(formData, 'isComplianceSupervisor', form) && (
          <BooleanInput
            {...rest}
            source="isComplianceSupervisor"
            label="Compliance Supervisor"
            onChange={makeFormValidWrapper(value => {
              if (value) {
                if (formData.isRegisteredRepresentative) {
                  notify('Removing Registered Representative role');
                  setFormFieldValue(form, 'isRegisteredRepresentative', false);
                }
              }
            })}
            isRequired={isFieldRequired(formData, 'isComplianceSupervisor')}
            disabled={shouldDisableField(formData, 'isComplianceSupervisor', () => (
              !formData.isComplianceSupervisor
                ? !canBeCompliancePerson({
                  ...formData,
                  // allow switching between compliance person types without having to first
                  // toggle the other one off
                  isRegisteredRepresentative: false,
                }) : !canBeNonCompliancePerson(formData)
            ))}
          />
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

const CreateEditComponent = compose(
  connect(({ companies, userAdminDocuments }) => ({
    companies: companies.companies,
    userCompany: companies.userCompany,
    isLoadingCompanies: companies.companiesLoading,
    AWSData: userAdminDocuments.AWSData,
    currentDocuments: userAdminDocuments.currentDocuments,
    AWSDataIsSet: userAdminDocuments.AWSDataIsSet,
    getPreSignedUrls,
    preSignedUrls: userAdminDocuments.preSignedUrls,
    removeAWSDataFile,
    setAllAWSData,
    setAWSData,
    setUnloadedFilesExist,
  })),
)(({
  companies,
  formType,
  isLoadingCompanies,
  userCompany,
  ...props
}) => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const [form, setForm] = useState(undefined);
  const [dropZoneIsProcessingFile, setDropZoneIsProcessingFile] = useState(false);
  const [dropZoneHasFileNotUploaded, setDropZoneHasFileNotUploaded] = useState(false);

  const uploadIsPending = dropZoneIsProcessingFile || dropZoneHasFileNotUploaded;

  useEffect(() => {
    dispatch(setUnloadedFilesExist(uploadIsPending));
  }, [dispatch, uploadIsPending]);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (props.id) {
      dispatch(getUserComplianceDocuments({ userId: props.id }));
    }
  }, [dispatch, props.id]);

  const columns = [
    { field: 'id', headerName: 'Document Id', flex: 1 },
    { field: 'filename', headerName: 'Document Name', flex: 1 },
    {
      field: 'updatedAt', headerName: 'Uploaded At', flex: 1, renderCell: params => `${nonOpWellsDateTime(new Date(params.row.updatedAt)).toFormat('MM / dd / yyyy / t ZZZZ')}`,
    },
    {
      field: 'createdByName', headerName: 'Uploaded By', flex: 1,
    },
    {
      field: 'download', headerName: 'Download', flex: 1, renderCell: params => <a href={params.row.downloadUrl} download target="_blank" style={{ color: '#3f51b5' }} rel="noreferrer"><IconSaveAlt /></a>,
    },
    {
      field: 'Delete', headerName: 'Delete', flex: 1, renderCell: params => <DeleteButtonWithDialog document={params} onDelete={() => deleteUserComplianceDocument({ userId: params?.row?.userId, documentId: params?.row?.id })} />,
    },
  ];

  const userForm = UserForm({
    companies,
    formType,
    resouce: props.resource,
    isLoadingCompanies,
    form,
    onForm: setForm,
    ...props,
  });

  return formType === 'create' ? (
    <Create
      {...getStandardCreateEditProps({ notify, isForCreate: true, ...props })}
    >
      {userForm}
    </Create>
  ) : (
    <>
      <Edit
        {...getStandardCreateEditProps({ notify, ...props })}
        actions={(
          <UserEditActions
            userCompany={userCompany}
            form={form}
          />
        )}
      >
        {userForm}
      </Edit>
      <div style={{ marginTop: '100px', width: '100%' }}>
        <Typography variant="h6">
          User Related Documents
        </Typography>
        <DataGrid
          style={{ margin: '15px 0' }}
          rows={props.currentDocuments}
          columns={columns}
          autoHeight
          autoPageSize
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
        />
        <div style={{
          width: '50%',
          margin: '35px auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
        >
          <DropZone
            AWSData={props.AWSData}
            preSignedUrls={props.preSignedUrls}
            savedDocuments={props.currentDocuments}
            setAWSData={setAWSData}
            getPreSignedUrls={getPreSignedUrls}
            removeAWSDataFile={removeAWSDataFile}
            onSetIsProcessingFiles={setDropZoneIsProcessingFile}
            onSetIsSomeFileNotUploaded={setDropZoneHasFileNotUploaded}
            placeholder="Upload any additional compliance related documents here."
            isSimpleView
            hideSaved
          />
          <Button
            disabled={
              !props.AWSData.find(doc => !doc.id)
              || uploadIsPending
            }
            onClick={() => dispatch(saveUserComplianceDocuments({ AWSData: props.AWSData, userId: props.id }))}
            variant="contained"
          >
            <span style={{ fontSize: '14px' }}>Save Uploaded</span>
          </Button>
        </div>
      </div>
    </>
  );
});

export const UserCreate = props => (
  <CreateEditComponent formType="create" {...props} />
);

export const UserEdit = props => (
  <CreateEditComponent formType="edit" {...props} />
);
