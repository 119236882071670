// actions
import * as A from '../actions/clone-listing-actions';

const initialState = {
  currentProperty: null,
  states: null,
  stateId: null,
  basins: null,
  basinId: null,
  counties: null,
  countyId: null,
  landingZones: null,
  preSignedUrls: undefined,
  preSignedUrlsIsLoading: false,
  AWSData: [],
  unloadedFilesExist: false,
};

const reducer = {
  cloneListing(state = initialState, action) {
    switch (action.type) {
      case A.reset.type:
        return initialState;

      case A.getCurrentProperty.success:
        return {
          ...state,
          currentProperty: action.payload.data,
          states: action.payload.states,
          basins: action.payload.basins,
          counties: action.payload.counties,
          stateId: action.payload.stateId,
          basinId: action.payload.basinId,
          countyId: action.payload.countyId,
          landingZones: action.payload.landingZones,
        };

      case A.getStates.type:
        return {
          ...state,
          basins: null,
          basinId: null,
          counties: null,
          countyId: null,
          landingZones: null,
        };

      case A.getStates.success:
        return { ...state, states: action.payload };

      case A.getBasins.type:
        return {
          ...state, stateId: action.payload, counties: null, countyId: null, landingZones: null,
        };

      case A.getBasins.success:
        return { ...state, basins: action.payload };

      case A.getCounties.type:
        return { ...state, basinId: action.payload, landingZones: null };

      case A.getCounties.success:
        return { ...state, counties: action.payload };

      case A.getLandingZones.type:
        return { ...state, countyId: action.payload };

      case A.setBasinId.type:
        return { ...state, basinId: action.payload };

      case A.setStateId.type:
        return { ...state, stateId: action.payload };

      case A.getLandingZones.success:
        return { ...state, landingZones: action.payload };

      case A.getPreSignedUrls.type:
        return { ...state, preSignedUrlsIsLoading: true };

      case A.getPreSignedUrls.success:
        return {
          ...state,
          preSignedUrls: action.payload,
          preSignedUrlsIsLoading: false,
        };

      case A.getPreSignedUrls.failure:
        return {
          ...state,
          preSignedUrls: initialState.preSignedUrls,
          preSignedUrlsIsLoading: false,
        };

      case A.setAWSData.type:
        return {
          ...state,
          AWSData: [
            ...state.AWSData.filter(doc => doc.key !== action.payload.key),
            action.payload,
          ],
        };

      case A.removeAWSDataFile.type:
        return { ...state, AWSData: [...state.AWSData.filter(file => file.key !== action.payload)] };

      case A.clearAWSData.type:
        return { ...state, AWSData: initialState.AWSData };

      case A.setUnloadedFilesExist.type:
        return { ...state, unloadedFilesExist: action.payload };

      default:
        return state;
    }
  },
};

export default reducer;
