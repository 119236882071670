import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import {
  getAllCompanies,
  getCompanyComplianceInfo,
  getUserCompany,
  getNonOpWellsSignatureDetails,
} from '../actions/companies-actions';

// api methods
import Api from 'api/companies';

function* ensureGetAllCompanies() {
  try {
    const { data } = yield call(Api.getAllCompanies);
    yield put({ type: getAllCompanies.success, payload: data });
  } catch (err) {
    yield put({ type: getAllCompanies.failure, err });
  }
}

function* watchGetAllCompany() {
  yield takeLatest(getAllCompanies.type, ensureGetAllCompanies);
  yield take(getAllCompanies.success);
}

function* ensureGetCompanyComplianceInfo({ payload: { companyId } }) {
  try {
    const { data } = yield call(Api.getCompanyComplianceInfo(companyId));
    yield put({ type: getCompanyComplianceInfo.success, payload: data });
  } catch (err) {
    yield put({ type: getCompanyComplianceInfo.failure, err });
  }
}

function* watchGetCompanyComplianceInfo() {
  yield takeLatest(getCompanyComplianceInfo.type, ensureGetCompanyComplianceInfo);
  yield take(getCompanyComplianceInfo.success);
}

function* ensureGetUserCompany({ payload: { id } }) {
  try {
    const { data } = yield call(Api.getUserCompany(id));
    yield put({ type: getUserCompany.success, payload: data });
  } catch (err) {
    yield put({ type: getUserCompany.failure, err });
  }
}

function* watchGetUserCompany() {
  yield takeLatest(getUserCompany.type, ensureGetUserCompany);
  yield take(getUserCompany.success);
}
function* ensureSignatureDetailsRequested() {
  try {
    const { data } = yield call(Api.getNonOpWellsSignatureDetails);
    yield put({ type: getNonOpWellsSignatureDetails.success, payload: data });
  } catch (err) {
    yield put({ type: getNonOpWellsSignatureDetails.failure, err });
  }
}

function* watchSignatureDetailsRequest() {
  yield takeLatest(getNonOpWellsSignatureDetails.type, ensureSignatureDetailsRequested);
}

export default function* companiesSagas() {
  yield all([
    fork(watchGetAllCompany),
    fork(watchGetCompanyComplianceInfo),
    fork(watchGetUserCompany),
    fork(watchSignatureDetailsRequest),
  ]);
}
