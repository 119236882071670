// actions
import * as A from '../actions/companies-actions';

const initialState = {
  companies: [],
  userCompany: undefined,
  companiesLoading: false,
  companiesLoaded: false,
  companyComplianceInfo: undefined,
  isLoadingCompanyComplianceInfo: false,
  nonOpWellsSignatureDetails: {},
};

export default {
  companies(state = initialState, action) {
    switch (action.type) {
      case A.getAllCompanies.type:
        return {
          ...state,
          companiesLoading: true,
        };

      case A.getAllCompanies.success:
        return {
          ...state, companies: action.payload, companiesLoading: false, companiesLoaded: true,
        };

      case A.getAllCompanies.failure:
        return { ...state, companiesLoading: false };

      case A.getCompanyComplianceInfo.type:
        return {
          ...state,
          isLoadingCompanyComplianceInfo: true,
        };

      case A.getCompanyComplianceInfo.success:
        return {
          ...state,
          companyComplianceInfo: action.payload,
          isLoadingCompanyComplianceInfo: false,
        };

      case A.getCompanyComplianceInfo.failure:
        return {
          ...state,
          isLoadingCompanyComplianceInfo: false,
        };

      case A.clearCompanyComplianceInfo.type:
        return { ...state, companyComplianceInfo: undefined };

      case A.getUserCompany.success:
        return { ...state, userCompany: action.payload };

      case A.getUserCompany.failure:
        return { ...state, userCompany: initialState.userCompany };

      case A.clearUserCompany.type:
        return { ...state, userCompany: initialState.userCompany };

      case A.clearState.type:
        return initialState;

      case A.getNonOpWellsSignatureDetails.type:
        return {
          ...state,
        };

      case A.getNonOpWellsSignatureDetails.success:
        return {
          ...state,
          nonOpWellsSignatureDetails: action.payload,
        };

      case A.getNonOpWellsSignatureDetails.failure:
        return {
          ...state,
        };

      default:
        return state;
    }
  },
};
