import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import DatePicker from 'now-frontend-shared/components/DatePicker';

const DateInputWithTimeZone = props => {
  const {
    disabled,
    minDate,
    parse,
    timeZone,
  } = props;

  const {
    input: {
      name,
      value,
      onChange,
    },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const dateValue = useMemo(() => {
    const parsedValue = parse(value);
    return parsedValue && new Date(parsedValue);
  }, [parse, value]);

  return (
    <DatePicker
      input={{
        name,
        value: dateValue,
        onChange,
      }}
      meta={{
        touched,
        error,
      }}
      minDate={minDate}
      timeZone={timeZone}
      required={isRequired}
      disabled={disabled}
    />
  );
};

DateInputWithTimeZone.propTypes = {
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  parse: PropTypes.func,
  timeZone: PropTypes.string,
};

DateInputWithTimeZone.defaultProps = {
  disabled: false,
  minDate: undefined,
  parse: value => value,
  timeZone: undefined,
};

export default DateInputWithTimeZone;
