import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getCurrentProperty = asyncAction('editListing/GET_CURRENT_PROPERTY');
export const getStates = asyncAction('editListing/GET_STATES');
export const getBasins = asyncAction('editListing/GET_BASINS');
export const getCounties = asyncAction('editListing/GET_COUNTIES');
export const getLandingZones = asyncAction('editListing/GET_LANDING_ZONES');
export const getPreSignedUrls = asyncAction('editListing/GET_PRE_SIGNED_URLS');

// sync action
export const reset = action('editListing/RESET');
export const setBasinId = action('editListing/SET_BASIN_ID');
export const setStateId = action('editListing/SET_STATE_ID');
export const setAllAWSData = action('editListing/SET_ALL_AWS_DATA');
export const setAWSData = action('editListing/SET_AWS_DATA');
export const setCurrentProperty = action('editListing/SET_CURRENT_PROPERTY');
export const removeAWSDataFile = action('editListing/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('editListing/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('editListing/SET_UNLOADED_FILES_EXIST');
