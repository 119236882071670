import React, { useState } from 'react';
import { Button, useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconVerified from '@material-ui/icons/VerifiedUser';
import IconDone from '@material-ui/icons/Done';
import IconCancel from '@material-ui/icons/Cancel';
import { generateFetchOptions } from '../helpers/fetch';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { parseServerError, ServerError } from 'now-shared/helpers/server-errors';

export const MakeWinnerButton = ({ record, onSuccess }) => {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();

  const bidStatus = record?.status.title;
  const isWinner = bidStatus === 'winner';
  const isSelectedWinner = bidStatus === 'winner selected';
  const isSold = bidStatus === 'sold';

  const onMakeWinnerClick = event => {
    event.preventDefault();
    event.stopPropagation();

    setShowDialog(true);
  };

  const handleWinner = async ({ event, useEscrowDotCom }) => {
    event.preventDefault();
    event.stopPropagation();

    const options = generateFetchOptions('PUT', {
      winnerBidId: record.id,
      useEscrowDotCom,
    });

    setShowDialog(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/properties/${record.property.id}/change-to-sold`, options);
    if (response.status === 200) {
      notify('Winner confirmed');
      onSuccess?.();
    } else {
      let message;
      try {
        message = parseServerError(await response.json()).friendlyMessage;
      } catch (error) {
        message = ServerError.Generic;
      }
      notify(message, 'warning');
    }
  };

  const handleCloseClick = event => {
    event.preventDefault();
    event.stopPropagation();

    setShowDialog(false);
  };

  return (
    <>
      <Button
        label={isWinner ? 'Winner' : isSelectedWinner ? 'Confirm Winner' : isSold ? 'Change Winner' : 'Make Winner'}
        onClick={onMakeWinnerClick}
        disabled={isWinner}
      >
        {(isWinner || isSelectedWinner) && (
        <IconVerified />
        )}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>
          {
        isSelectedWinner
          ? `Are you sure you want to confirm ${getUserFullName(record?.user)} as the winner?`
          : isSold
            ? 'Another winner was previously confirmed. '
              + `Are you sure you want to make ${getUserFullName(record?.user)} the new winner?`
            : 'This is not the seller selected winner. '
                + `Are you sure you want to make ${getUserFullName(record?.user)} the winner?`
      }
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={event => handleWinner({ event, useEscrowDotCom: true })}
            label="Confirm, using escrow.com transaction"
            variant="outlined"
            key="confirmWithEscrowDotComButton"
          >
            <IconDone />
          </Button>
          <Button
            onClick={event => handleWinner({ event, useEscrowDotCom: false })}
            label="Confirm, using alternative closing method transaction"
            variant="outlined"
            key="confirmWithAltTransactionButton"
          >
            <IconDone />
          </Button>
          <Button
            onClick={handleCloseClick}
            label="ra.action.cancel"
            variant="outlined"
            key="cancelButton"
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
