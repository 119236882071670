import { call, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

export default function* ensureNotifications() {
  yield takeEvery(
    e => e.type.includes('/FAILURE'),
    function* logger(action) {
      const message
        = (action.err.response?.data.message[0].field === 'email'
          && (action.err.response?.data.message[0].messages.isEmail
            || action.err.response?.data.message[0].messages.IsEmailAlreadyExistConstraint))
        || (typeof action.err.response?.data.message === 'string' && action.err.response.data.message)
        || 'Something went wrong';

      console.error('saga error:', action);
      yield call(toast.error, message, { position: 'top-center' });
    },
  );
}
