/**
 * NOTE: a change to this value must be accompanied by a database migration
 */
export const documentTemplateTypeNameMaxChars = 100;

/**
 * NOTE: changes to enum values here must be accompanied by a database migration.
 *
 * @readonly
 * @enum {'buyerAgreement'}
 */
export const DocumentTemplateTypeName = {
  /**
   * @readonly
   * @type {'buyerAgreement'}
   */
  BuyerAgreement: 'buyerAgreement',
};

export const DocumentTemplateTypeLabel = {
  [DocumentTemplateTypeName.BuyerAgreement]: "Buyer's Agreement",
};
