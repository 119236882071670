import Api from 'api/properties-admin';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { SubmissionError } from 'redux-form';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import {
  useNotify,
} from 'react-admin';

// components
import ListingForm from 'now-frontend-shared/components/ListingForm';

// layouts
import HeadSection from 'now-frontend-shared/layouts/AuthSections/HeadSection';

// store
import {
  clearAWSData,
  getBasins,
  getCounties,
  getCurrentProperty,
  getLandingZones,
  getPreSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
} from 'store/actions/clone-listing-actions';
import { getAllCompanies } from 'store/actions/companies-actions';

// helpers
import { getAuthorizationHeader } from '../../auth/auth-helpers';

const CloneListing = ({ listingId, ...restOfProps }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();

  const { currentProperty } = restOfProps;
  false && console.debug('currentProperty', currentProperty);

  useEffect(() => {
    dispatch(getCurrentProperty(listingId));
    dispatch(getAllCompanies());
  }, [listingId, dispatch]);

  const onSubmit = useCallback(async formData => {
    false && console.debug('onSubmit:currentProperty', currentProperty);
    if (!currentProperty) {
      throw new Error();
    }
    formData = {
      ...formData,
      originalListingId: currentProperty.id,
    };
    try {
      const {
        data: { id },
      } = await Api.sendPropertyData({
        data: JSON.stringify(formData),
        headers: { Authorization: getAuthorizationHeader() },
      });
      history.replace(`/properties/${id}`);
    } catch (err) {
      console.error(err);
      const message = 'Something went wrong';
      notify(message);
      throw new SubmissionError(message);
    }
  }, [currentProperty, history, notify]);

  return (
    <ListingForm
      formName="cloneListing"
      isBasedOnExisting
      isForCreate
      onSubmit={onSubmit}
      { ...restOfProps }
    >
      <HeadSection heading="Create Reopened Listing" path={`/properties/${listingId}`} />
    </ListingForm>
  );
};

CloneListing.propTypes = {
  listingId: PropTypes.string.isRequired,
  // TODO: [TYPE] add all missing property types
};

const mapStateToProps = ({ cloneListing, companies }) => ({
  AWSData: cloneListing.AWSData,
  basins: cloneListing.basins,
  clearAWSData,
  companies: companies.companies,
  counties: cloneListing.counties,
  currentProperty: cloneListing.currentProperty,
  getLandingZones,
  getBasins,
  getCounties,
  getPreSignedUrls,
  isLoadingCompanies: companies.isLoadingCompanies,
  landingZones: cloneListing.landingZones,
  preSignedUrls: cloneListing.preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
  states: cloneListing.states,
  unloadedFilesExist: cloneListing.unloadedFilesExist,
});

export default compose(
  connect(mapStateToProps),
)(CloneListing);
