import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import { getListingAnalytics } from '../actions/listingAnalyticsActions';

// api methods
import Api from 'api/properties';

function* ensureGetListingAnalytics({ payload: { listingId } }) {
  try {
    const { data } = yield call(Api.getListingAnalytics(listingId));
    yield put({ type: getListingAnalytics.success, payload: data });
  } catch (err) {
    yield put({ type: getListingAnalytics.failure, err });
  }
}

function* watchGetListingAnalytics() {
  yield takeLatest(getListingAnalytics.type, ensureGetListingAnalytics);
  yield take(getListingAnalytics.success);
}

export default function* listingAnalyticsSagas() {
  yield all([fork(watchGetListingAnalytics)]);
}
