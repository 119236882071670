import React from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  BooleanField,
  NumberField,
  EmailField,
  TextField,
  Filter,
  TextInput,
  NullableBooleanInput,
} from 'react-admin';
import DeleteButtonWithConfirmation from '../components/deleteButtonWithConfirmation';
import { CustomPagination } from '../components/pagination';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  shouldIncludeComplianceUsersInAdminsList,
  shouldIncludeSuperAdminsInAdminsList,
} from 'now-shared/config/admin-settings';
import { SortDirection } from 'now-shared/enums/sort-direction';

export const AdminsFilters = props => (
  <Filter {...props}>
    <TextInput label="Name / Email" source="search" alwaysOn />
    <NullableBooleanInput label="Active" source="active" alwaysOn />
  </Filter>
);

export const AdminList = props => (
  <List
    {...props}
    filters={<AdminsFilters />}
    sort={{
      field: 'id',
      order: SortDirection.Descending,
    }}
    pagination={<CustomPagination />}
    exporter={false}
    bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
    perPage={50}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" label="ID" />
      <DateFieldWithTimeZone
        source="createdAt"
        label="Created"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="lastSignIn"
        label="Last Sign In"
        showTime
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <BooleanField source="active" label="Active" />
      <BooleanField source="isVerifiedEmail" label="Email Verified" />
      <FunctionField render={record => getUserFullName(record)} label="Name" sortBy="firstName" />
      <EmailField source="email" label="Email" />
      <BooleanField source="isAdmin" label="Admin" />
      {shouldIncludeSuperAdminsInAdminsList && (
        <BooleanField source="isSuperAdmin" label="Super Admin" />
      )}
      {shouldIncludeComplianceUsersInAdminsList && (
        <BooleanField source="isComplianceSupervisor" label="Compliance Supervisor" />
      )}
      {shouldIncludeComplianceUsersInAdminsList && (
        <BooleanField source="isRegisteredRepresentative" label="Registered Representative" />
      )}
      <TextField source="phoneNumber" label="Phone" />
    </Datagrid>
  </List>
);
