import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  main: { background: '#CECECE' },
  avatar: {
    height: 10,
  },
  icon: { display: 'none' },
});

const CustomLoginForm = withStyles({
  button: { background: '#F15922' },
})(LoginForm);

const CustomLogin = props => (
  <Login
    backgroundImage=""
    { ...props }
  >
    <CustomLoginForm />
  </Login>
);

export default withStyles(styles)(CustomLogin);
